import { environment } from "@hiptraveler/environments";
import { SupportedLanguage } from "./interfaces";

/**
 * Window objects *
 **/ 

export const appWindowObjectPrefix = '__ht_global_window_variable__'

/*  */
export const appVersionKey: string = `${appWindowObjectPrefix}_app_version`;

/* Used to store the store object to enable access in any non angular connected files */
export const globalStateManagementStoreKey: string = `${appWindowObjectPrefix}_state_management_store`;

/*  */
export const i18nServiceStoreKey: string = `${appWindowObjectPrefix}_i18n_service`;

/*  */
export const i18nTranslationsKey: string = `${appWindowObjectPrefix}_i18n_translations`;

/*  */
export const preventRequestCancellation: string = `${appWindowObjectPrefix}_prevent_request_cancellation`;

/* Used to store the ChromaJS namespace to prevent error in server state */
export const chromaNamespaceKey: string = `${appWindowObjectPrefix}_chromajs_namespace`;

/* Used to store the ShowdownJS namespace to prevent error in server state */
export const showdownNamespaceKey: string = `${appWindowObjectPrefix}_showdownjs_namespace`;

/* Used to store the Leaflet namespace to prevent error in server state */
export const leafletNamespaceKey: string = `${appWindowObjectPrefix}_leaflet_namespace`;

/* Used to store the Medium Editor namespace to prevent error in server state */
export const mediumEditorNamespaceKey: string = `${appWindowObjectPrefix}_medium_editor_namespace`;

/* Used to access the access token in snapshot on signout */
export const signoutAccessToken: string = `${appWindowObjectPrefix}_signout_access_token`;

/* Used to store current i18n */
export const selectedClientLangBeforeAction: string = `${appWindowObjectPrefix}_selected_client_language_before_action`;

/* Used to store add itinerary data */
export const itineraryBasicInfoItinerariesKey: string = `${appWindowObjectPrefix}_itinerary_basic_info_itineraries`;

/*  */
export const itineraryBasicInfoAuthorKey: string = `${appWindowObjectPrefix}_itinerary_basic_info_author`;

/*  */
export const requestEndpointParamValue: string = `${appWindowObjectPrefix}_request_endpoint_param_value`;

/*  */
export const requestLocationPendingKey: string = `${appWindowObjectPrefix}_request_location_pending_key`;

/*  */
export const filterQueryStringKey: string = `${appWindowObjectPrefix}_filter_query_string`;

/*  */
export const openedWindowPopUp: string = `${appWindowObjectPrefix}_opened_window_pop_up`;

/*  */
export const addItineraryRequestPending: string = `${appWindowObjectPrefix}_add_itinerary_request_pending`;

/*  */
export const ofActionDispatchedSignOutNoAuthState: string = `${appWindowObjectPrefix}_of_action_dispatched_sign_out_no_auth_state`;

/**
 * Default constants *
 **/ 

/*  */
export const appId: string = 'hiptraveler';

/*  */
export const appVersion: string = '0.0.786';

/*  */
export const appVersionString: string = `Last update: March 26, 2025 (${environment?.build}: v${appVersion})`;

/*  */

export const SUPPORTED_LANG: SupportedLanguage[] = [ 'en', 'de', 'es', 'fr', 'it', 'zh' ];
/*  */
export const DEFAULT_LANG: SupportedLanguage = 'en';

/*  */
export const SEARCH_ROUTES: string[] = [ 'search', 'experiences', 'thingstodo', 'hotels', 'foodanddrink', 'community' ];

/*  */
export const apiPort: number = 8080;

/**
 * Local Storage keys *
 * */ 

const hiptravelerKeyPrefix: string = '_ht_';

/*  */
export const HT_SEARCH_LOCATION_KEY: string = `${hiptravelerKeyPrefix}sloc`;

/*  */
export const HT_COOKIE_POLICY_KEY: string = `${hiptravelerKeyPrefix}cookie_policy`;

/*  */
export const HT_FROM_WIDGET_SIGNOUT = `${hiptravelerKeyPrefix}from_widget_signout`;

/** 
 * Session storage keys *
 **/ 

/*  */
export const updateOrderItinerariesKey: string = 'update_order_itineraries';

/*  */
export const globalStoreKey: string = 'global_store';

/*  */
export const globalStoreItineraryDataKey: string = `${globalStoreKey}_itinerary_data`;

/*  */
export const pendingAuthProcessKey: string = `pending_auth_process`;

/* This is utilized in the AddItinerary action to ensure that the experience finder dialog doesn't close during search navigation. */
export const addItineraryUXStateKey: string = `add_itinerary_ux_state`;

/* The variable for additional endpoints that will be ignored by the request cancellation interceptor. */
export const requestCancellationIgnoredEndpointsKey: string = `request_cancellation_ignored_endpoints`;

/* This variable stores the target API endpoints for request cancellation. */
export const dynamicRequestCancellationEndpointsKey: string = `dynamic_request_cancellation_endpoints`;

/* Used to manually handle and prevent the experience finder open and close state. */
export const preventExperienceFinderBubbleStateKey: string = `prevent_experience_finder_bubble_state`;

/**
 * CDK Overlay classes *
 **/ 

/*  */
export const ITINERARY_ACTION_PANEL_CLASS: string = 'itinerary-action-overlay-panel';

/*  */
export const ITINERARY_ACTION_PANEL_BACKDROP_CLASS: string = `${ITINERARY_ACTION_PANEL_CLASS}-backdrop`;

/*  */
export const LIST_PANEL_PANEL_CLASS: string = 'search-page-root-filters-list-panel';

/*  */
export const LIST_PANEL_PANEL_BACKDROP_CLASS: string = `${LIST_PANEL_PANEL_CLASS}-backdrop`;

/**
 * Global signal keys *
 **/ 

/*  */
export const siteNavigationCloseActionKey: string = `site_navigation_action_button_close_trigger_key`;
