
<div class="bottom-nav--selection" (click)="navigate('explore')">
  <mat-icon svgIcon="navbar-c.explore"></mat-icon>
  <span class="bottom-nav--selection--text" translate="navbar.bottom-nav-text-explore"></span>
</div>

<div class="bottom-nav--selection" (click)="navigate('trips')">
  <mat-icon svgIcon="navbar-c.trips"></mat-icon>
  <span class="bottom-nav--selection--text" translate="navbar.bottom-nav-text-trips"></span>
</div>

<div class="bottom-nav--selection" (click)="navigate('profile')">
  
  <ng-container *ngIf="authenticated$ | async;else noPicUrlFallback">
    <ng-container *ngIf="(userState$ | async) as user">
      <img [lazyLoad]="user.picUrl" [defaultImage]="authenticatedProfilePlaceholder" [errorImage]="authenticatedProfilePlaceholder" draggable="false" [alt]="user.uTitle">
    </ng-container>
  </ng-container>
  <ng-template #noPicUrlFallback>
    <mat-icon svgIcon="navbar-c.profile"></mat-icon>
  </ng-template>

  <span class="bottom-nav--selection--text" translate="navbar.bottom-nav-text-profile"></span>
</div>

<div class="bottom-nav--experience-finder">
  <div class="bottom-nav--experience-finder-bubble-wrapper">
    <div class="bottom-nav--experience-finder-bubble" (click)="openAddItineraryDialog()">
      <mat-icon svgIcon="navbar-c.exp-finder"></mat-icon>
    </div>
  </div>
</div>
